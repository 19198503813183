import React, { useState , useRef, useEffect} from "react";
import useCheckRecordStatusAndTypes from 'hooks/useCheckRecordStatusAndTypes';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
interface IRecordManagementGrid {
  arrData2: any[];
  props: any;
}
const RoyalDecreeFormsGrid: React.FC<IRecordManagementGrid> = ({
  arrData2,
  props,
}) => {
  const tooltipRef = useRef<any>(null);  // Cambia tooltip a tooltipRef
  const [gridKey, setGridKey] = useState(0);
  const { checkFormStatusRD } = useCheckRecordStatusAndTypes();

  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });

  const [sortingOptions] = useState<Object>({
    columns: [{ field: "fecha", direction: "Descending" }],
  });

  const Template = (props: any): any => {
    return (
      <div className="link-document">
        <a
          href={props.uri}
          rel={props.nombre}
          className="link-bold"
        >
          <span className="edit-delta-icon"></span>
        </a>
      </div>
    );
  };

  const rowSelected = (args: any) => {
    if (args != null) {
      const idForm = args.data.Id;
      const idPrestacion = props.match.params.id; 
      let redirectPath = "";
  
      switch (args.data.Tipo) {
        case 5:
          redirectPath = `/solicitud-pago-directo-incapacidad-temporal/${idPrestacion}/${idForm}`;
          break;
        case 22021:
          redirectPath = `/formulario-real-decreto/${idPrestacion}/${idForm}`;
          break;
          case 112021 :
            redirectPath = `/formulario-real-decreto/${idPrestacion}/${idForm}`;
            break;
        default:
          redirectPath = `/view404`;
          break;
      }
  
      props.history.push({
        pathname: redirectPath,
      });
    }
  };
  
  const transformedData = arrData2?.map(form => ({
    ...form,
    EstadoGeneralRevision: checkFormStatusRD(form.EstadoGeneralRevision),
  }));
  useEffect(() => {
    setGridKey(prevKey => prevKey + 1); 
  }, [props.match.params.id]);  


  const beforeRender = (args: any) => {
    const cell = args.target.closest("td");
    const isVerColumn = cell?.querySelector(".edit-delta-icon");
    
    if (isVerColumn) {
      tooltipRef.current.content = "Ver";
    } else {
      tooltipRef.current.content = cell?.innerText || ""; 
    }
  };

  return (
    <div>
      <TooltipComponent
        ref={tooltipRef}
        target=".e-rowcell"
        beforeRender={beforeRender}
      >
        <GridComponent
         key={gridKey} 
          dataSource={transformedData}
          allowPaging={true}
          allowSorting={true}
          rowSelected={rowSelected}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
          enablePersistence={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="TipoDescripcion"
              headerText="Tipo"
              width="160"
              textAlign="Center"
            />
            <ColumnDirective
              field="EstadoDescripcion"
              headerText="Estado"
              width="140"
              textAlign="Center"
            />
            <ColumnDirective
            field="Fecha"
            headerText="Fecha Fin"
            type="date"
            format="dd/MM/yyyy"
            width="100"
          />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </TooltipComponent>
    </div>
  );
};

export default RoyalDecreeFormsGrid;
