import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import "moment/locale/es";
import { appointment } from "@solimat/solimat-web-endpoint";
//services
import useGetMainCenters from "services/company/useGetMainCenters";
import useGetRoyalDecreeFormsCount from '../../../hooks/useGetRoyalDecreeFormsCount'
// blocks
import ImgMobileApp from '../../../img/06.png'
import NewsComponent from "../../blocks/News/News";
//images
import slider from "../../../img/01.png";
import build from "../../../img/Hospital_San_Jose_de_Solimat.jpg";
import experience from "../../../img/04.svg";
import trust from "../../../img/05.svg";
import health from "../../../img/06.svg";
import useDecode from "../../../hooks/useDecode";
// styles
import "./Home.scss";
import Carrusel from "./Carrusel";
import { checkIsImpersonation } from "hooks/useSetToken";
// import FormValidatePhone from "components/blocks/Forms/FormValidatePhone";
// import Modal from "components/blocks/Modal/Modal";
// import useModals from "hooks/useModal";
// import useGetUserById from "services/authenticate/useGetUserById";
// import useGenerateOtpPhone from "services/authenticate/useGenerateOtpPhone";
import FormsImage from '../../../img/formularios.png'
const BASE_URL = process.env.REACT_APP_CITAS_PATH || "";
const FUNCTIONS_KEY = process.env.REACT_APP_CITAS_KEY || "";
const apiAppointment = new appointment.AppointmeService(
  BASE_URL,
  FUNCTIONS_KEY
);


interface Root {
  rows: InextAppointment[];
  total: number;
}

interface InextAppointment {
  id: string;
  citaId: string;
  userId: string;
  personaId: string;
  fecha: string;
  mensaje: string;
  nombrePrueba: string;
  especialidad: string;
  tipoActividad: string;
  estado: string;
  realizada: boolean;
  reprogramada: boolean;
  incomparecencia: boolean;
  doctor: Doctor;
  centro: Centro;
  linkVideoConsulta: string;
}

interface Doctor {
  nombre: string;
}

interface Centro {
  centroId: string;
  nombre: string;
  descripcion: string;
  codigoPostal: string;
  provincia: string;
  localidad: string;
  tipoCalle: string;
  nombreCalle: string;
  numeroCalle: string;
  letra: string;
  piso: string;
  latitud: number;
  longitud: number;
  telefono: string;
}

//

const Slider = () => {
  return (
    <div className="hero-container">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="img-container">
              <img src={slider} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NextAppoinment = ({ userId }: any) => {
  const [resolvedAppointment, setResolvedAppointment] = useState(true);
  const [nextAppointment, setNextAppointment] = useState<InextAppointment>({
    id: "",
    citaId: "",
    userId: "",
    personaId: "",
    fecha: "",
    mensaje: "",
    nombrePrueba: "",
    especialidad: "",
    tipoActividad: "",
    estado: "",
    realizada: true,
    reprogramada: false,
    incomparecencia: false,
    doctor: {
      nombre: "Doctor",
    },
    centro: {
      centroId: "",
      nombre: "",
      descripcion: "",
      codigoPostal: "",
      provincia: "",
      localidad: "",
      tipoCalle: "",
      nombreCalle: "",
      numeroCalle: "",
      letra: "",
      piso: "",
      latitud: 0,
      longitud: 0,
      telefono: "",
    },
    linkVideoConsulta: "",
  });
  // const tokenResponse = useDecode("token");
  // const userId = tokenResponse && tokenResponse.extension_id

  useEffect(() => {

    apiAppointment
      .getNextAppointment(userId && userId)
      .then((data: any) => {
        if (data.data?.rows?.length) {
          setNextAppointment(data && data.data?.rows[0]);
        }

        Moment().locale("es");
        setResolvedAppointment(true);
      })
      .catch((err: any) => console.log(err));

  }, [userId]);
  return (
    <>
      {nextAppointment?.id && (
        <section className="container">
          <div className={`row next-appointment bg-photo`}>
            <div className="col-md-7 offset-lg-1">
              <div className="row">
                <div className="col-md-7">
                  <h2 className="section-title_big">Próxima Cita</h2>
                  <ul className="appointment-block">
                    <li className="appointment-info">
                      <span className="appointment-header">Fecha:</span>
                      <span className="appointment-detail">
                        {Moment(nextAppointment.fecha).format("LL")}
                      </span>
                    </li>
                    <li className="appointment-info">
                      <span className="appointment-header">Hora:</span>
                      <span className="appointment-detail">
                        {Moment(nextAppointment.fecha).format("HH:mm")}h
                      </span>
                    </li>
                    <li className="appointment-info">
                      <span className="appointment-header">Lugar:</span>
                      {!nextAppointment?.linkVideoConsulta ? (
                        <span className="appointment-detail">
                          {nextAppointment.centro.descripcion}
                        </span>
                      ) : (
                        <span className="appointment-detail">
                          Video Consulta
                        </span>
                      )}
                    </li>
                    <li className="appointment-info">
                      <span className="appointment-header">Especialidad:</span>
                      <span className="appointment-detail">
                        {nextAppointment.especialidad}
                      </span>
                    </li>
                    {!nextAppointment?.linkVideoConsulta ? (
                      <li className="appointment-info">
                        <span className="appointment-header">Dirección:</span>
                        <span className="appointment-detail">
                          {` ${nextAppointment.centro.tipoCalle} ${nextAppointment.centro.nombreCalle},  ${nextAppointment.centro.numeroCalle}, ${nextAppointment.centro.provincia}`}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    <li className="appointment-info">
                      <span className="appointment-header">Telefono:</span>
                      <span className="appointment-detail">
                        {` ${nextAppointment.centro.telefono}`}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-5">
                  <Link
                    to={`/mis-citas/${nextAppointment.id}`}
                    className="btn-round"
                  >
                    VER CITA
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const MissionBussines = () => {
  return (
    <>
      <div className="container remarkable_section bg-accent">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="row">
                <div className="col-md-4">
                  <div className="content-block">
                    <div className="img-container">
                      <img src={experience} alt="icono" />
                    </div>
                    <h3>Experiencia</h3>
                    <p>
                      "Nuestro compromiso con los trabajadores, autónomos y
                      sociedad en general, se basa en la dilata experiencia de
                      servicio que acumulamos. Esto ha sido fruto del trabajo
                      constante y del esfuerzo en buscar soluciones que redunden
                      en soluciones óptimas para nuestras empresas y
                      colaboradores."
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content-block">
                    <div className="img-container">
                      <img src={trust} alt="icono" />
                    </div>
                    <h3>Confianza</h3>
                    <p>
                      "Queremos poner nuestra experiencia, conocimientos y
                      tecnologías a tu servicio para brindarte una atención de
                      calidad, y un trato humano. Con ello pretendemos alcanzar
                      una mayor satisfacción del paciente, la adherencia al
                      tratamiento y la mejor salud laboral posible."
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="content-block">
                    <div className="img-container">
                      <img src={health} alt="icono" />
                    </div>
                    <h3>Salud</h3>
                    <p>
                      "Disponemos de un colectivo de personas con una alta
                      cualificación y experiencia en la atención sanitaria, el
                      soporte de las prestaciones de Seguridad Social, entre
                      otras disciplinas. Todos juntos, pacientes y mutua,
                      lograremos una mejor salud laboral."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const InfoRoyalDecreeForm: React.FC = () => {
  const { getRoyalDecreeFormsCount, royalDecreeFormsCount} = useGetRoyalDecreeFormsCount()
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  useEffect(() => {
    getRoyalDecreeFormsCount(userId)
  }, [])

  return (
    <>
      {royalDecreeFormsCount && royalDecreeFormsCount.Cantidad > 0 ?
        <div className="container">
          <div className="container container-info-royal-decree-form">
            <div className="container container-texts-info-royal-decree-form">
              <h2 className="section-title_big">Auditoría Prestación Extraordinaria {royalDecreeFormsCount?.Tipo ? royalDecreeFormsCount?.Tipo : '' }</h2>
              <div className="container-img-text-info-royal-decree-form">
                <span className="text-info-royal-decree-form">Ya tiene a su disposición, en el apartado de <b>Notificaciones</b>, el formulario de la Auditoría {royalDecreeFormsCount?.Tipo ? royalDecreeFormsCount?.Tipo : '' } donde le indicamos la documentación que nos debe remitir.</span>
              </div>
            </div>

          </div>
        </div>
      : ''}

    </>

  )


}



const Notifications = (Props: any) => {
  return (
    <section className="container links-section">
      <Carrusel></Carrusel>
      {!Props.isImpersionation &&
        <div className="col">
          <div className="link-block-center">
            <h2 className="section-title_big">¿Tienes alguna duda?</h2>
            <Link to="/comunicaciones" className="btn-round blue">
              CONTACTA CON TU GESTOR
            </Link>
          </div>
        </div>
      }
    </section>
  );
};

const Info = (props: any) => {
  const {
    getMainCenters,
    mainCenters
  } = useGetMainCenters();
  useEffect(() => {
    getMainCenters()
    return () => {
    }
  }, []);

  const handleRedirectCenter = (id: string, props: any) => {
    props.props.history.push({
      pathname: `/red-de-centros/centro/${id}`,
    });
  };

  return (
    <div className="container centers-section">
      <div className="row">
        <div className="col-lg-4 centers-link-block">
          <div className="centers-img-container">
            <img src={build} alt="Hospital San José de Solimat" />
          </div>
          <h2 className="section-title_big">Red de Centros</h2>
          <Link to="/red-de-centros" className="btn-text icon-right">
            Consultar todos los Centros
          </Link>
        </div>
        <div className="col-lg-8 centers-list">
          <div className="card-columns">
            {mainCenters &&
              mainCenters.map((center: any, i: number) => {
                return (
                  <div
                    className="card"
                    onClick={() => handleRedirectCenter(center.Id, props)}
                    key={i}
                  >
                    <div className="card-header card-title article-title_medium">
                      {" "}
                      {center.Descripcion}
                    </div>
                    <div className="card-body body-card-center">
                      <p className="text-address-card">
                        {`${center.TipoViaDescripcion} ${center.NombreCalle} ${center.NumeroCalle}  C.P ${center.CodigoPostal}, ${center.LocalidadDescripcion} `}
                      </p>
                    </div>
                    <div className="card-footer">
                      <small className="text-muted">
                        <a href="tel:+34967511417">
                          Telf: {center.Telefono ? center.Telefono : ""}
                        </a>
                      </small>
                    </div>
                  </div>
                );
              })}
          </div>
          {/* <ul className="row centers-list">
            {mainCenters.map((center: any, i: number) => {
              return (
                <li className="col-lg-6 col-md-6 text-card-center" key={i}>
                  <h3
                    className="article-title_medium"
                    onClick={() =>
                      handleRedirectCenter(center.Id,props)
                    }
                  >
                    {center.Descripcion}
                  </h3>
                  <p>{`${center.NombreCalle} ${center.NumeroCalle}  CP ${center.CodigoPostal}, ${center.LocalidadDescripcion} `}</p>
                  <a href="tel:+34967511417">
                    Telf: {center.Telefono ? center.Telefono : ""}
                  </a>
                </li>
              );
            })}
          </ul> */}
        </div>
      </div>
    </div>
  );
};

const MobileApp = () => {
  return (
    <div className="remarkable_section bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col">
                <div className=" app-section">
                  <div className="container-img-mobileapp col-md-6 col-sm-12">
                    <img src={ImgMobileApp} alt="Imagen movil" />
                  </div>
                  <div className="container-text-mobileapp col-md-6 col-sm-12">
                    <h2 className="section-title_big">
                      Descarga Solimat App
                    </h2>
                    <p className="descriptive-text">
                      “Próximamente os informaremos de la disponibilidad de
                      nuestras app para iOS y Android. En estas apps encontrareis
                      todos los servicios que nuestro portal os brinda, adaptados
                      a dispositivos móviles”.
                    </p>
                  </div>
                  {/* <Link to="#top" className="btn-round">
                    VER MÁS
                  </Link> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const Home: React.FC = (props: any) => {
  // const [dataUser, setDataUser] = useState(true);
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const isImpersonation = tokenResponse && checkIsImpersonation(tokenResponse);
  // const [phoneId, setPhoneId] = useState("");
  // const [patientPhone, setPatientPhone] = useState("");

  // const {
  //   collapsed,
  //   handleOpenModal,
  //   handleCloseModal,
  //   handleDisableCloseButton,
  //   disabledCloseButton,
  // } = useModals();
  // const { UserById, getCompleteUser } = useGetUserById();
  // const { generateOtpPhone } = useGenerateOtpPhone();
  // const asterisk = '*****'
  // useEffect(() => {
  //   // getCompleteUser(userId);
  // }, []);
  // useEffect(() => {
  //   if (UserById !== null) {
  //     let idPhone = UserById?.telefonos[0].id;
  //     setPhoneId(idPhone);
  //     setPatientPhone(UserById?.telefonos[0].numero.substring(5))

  //     let data = {
  //       usuarioId: userId,
  //       telefonoId: idPhone,
  //     };
  //     if (!UserById.telfValidado && dataUser) {
  //       handleOpenModal();
  //       generateOtpPhone(userId, idPhone, data);
  //       setDataUser(false);
  //     }
  //   }
  // }, [UserById]);

  // useSetToken();
  return (
    <div className="home">
      <NewsComponent />
      <Slider />
      <InfoRoyalDecreeForm></InfoRoyalDecreeForm>
      {userId !== undefined ? <NextAppoinment userId={userId && userId} /> : ""}
      <Notifications isImpersionation={isImpersonation} />
      <MissionBussines />
      <Info props={props} />
      <MobileApp />

      {/* <Modal
        disabledCloseButton={disabledCloseButton}
        collapsed={collapsed}
        onClose={handleCloseModal}
        title={"Validación de Teléfono"}
        subtitle={
          `Necesitamos verificar su número telefónico, introduzca el código que hemos enviado a su teléfono *****${patientPhone}`
        }
      >
        <FormValidatePhone
          handleDisableCloseButton={handleDisableCloseButton}
          onClose={handleCloseModal}
          userId={userId}
          phoneId={phoneId}
        />
      </Modal> */}


    </div>
  );
};

export default Home;
