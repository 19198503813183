export const useCheckTypeNotification = () => {
  const checkTypeNotification = (data: any) => {

    // 0 Appointment, cita
    // 1 Transaction, 
    // 2 Record, prestacion
    // 3 ConfirmationPart, parte de confirmacion
    // 4 DischargeLeavePart, parte alta baja
    // 5 ManagementDocument, documentos generales
    // 6 SumaryCATA, no exite
    // 7 WithoutLeavePart, parte sin baja
    // 8 WorkerEnvironment, no exite
    // 9 GeneralExpense, no existe
    // 10 IMSSummary, resumen IMS
    // 11 OrthoprostheticReplacement, reposicion ortoprotesica
    // 12 Recovery, recobro
    // 13 RegulatoryBaseCATA, base reguladora cata
    // 14 SpecialBenefitsCommission, comision prestaciones especiales
    // 15 TSDPayment, compensacion SLD
    // 16 HealthcareDocument, documentos asistenciales
    // 17 Hospitalization, 
    // 18 OperationRoom, quirofano
    // 19 Rehabilitation, 
    // 20 Visit, ambulatorio
    // 21 General,
    // 22 Survey

    let historyObject = {
      pathname: ''
    };
    if (data.scope && data.scope === 1) { //prestaciones_pacientes
      data.record_type = data.tipoPrestacion;
      data.record_id = data.prestacionId;
      data.type = data.tipoComponente;
    }
    switch (data.scope) {
      case 0: // Citas
        historyObject = {
          pathname: `/mis-citas/${data.componenteId}`
        }
        return historyObject;
      case 1: // Prestaciones Pacientes
        return checkTypeNotificationPresatcionesPacientes(data);

      case 2: // comunicaciones
        historyObject = {
          pathname: `/comunicaciones`
        }
        return historyObject;
      case 3: // peticiones externas
        historyObject = {
          pathname: `/mis-citas/externa/${data.componenteId}`
        }
        return historyObject;
      default:
        return;
    }

  }
  const checkTypeNotificationPresatcionesPacientes = (data: any) => {

    let historyObject = {
      pathname: `/historial/${data.record_type}_${data.record_id}`
    };
    switch (data.type) {
      case 0: // Parte confirmacion
      case 1: // parte alta baja
      case 2: // parte sin baja
        historyObject = {
          pathname: `/historial/${data.record_type}_${data.record_id}/administracion/${data.type}__${data.componenteId}`
        }
        return historyObject;

      case 100: // BaseReguladoraCATA
      case 101: // BaseReguladora
      case 102: // ComisionPrestacionesEspeciales
      case 103: // CompensacionSLD
      case 104: // GastosAsistencial
      case 105: // GastosFarmacia
      case 106: // GastosJuridico
      case 107: // GastosTransporte
      case 108: // Recobros
      case 109: // Regularizacion
      case 110: // ReposicionOrtoprotesica
      case 111: // ResumenIMS
      case 112: // PagoDirecto
        historyObject = {
          pathname: `/historial/${data.record_type}_${data.record_id}/económico/${data.type}__${data.componenteId}`
        }
        return historyObject;

      case 200: // EpisodioAmbulatorio
      case 201: // CentroExterno
      case 202: // Hospitalizacion
      case 203: // Imagen
      case 204: // Quirofano
      case 205: // Rehabilitacion
      case 206: // Urgencia

        historyObject = {
          pathname: `/historial/${data.record_type}_${data.record_id}_${data.tipoContingencia}/informacion-asistencial/${data.type}/${data.componenteId}`
        }
        return historyObject;
      case 300: // formulario-real-decreto

        historyObject = {
          pathname: `/formulario-real-decreto/${data.record_type}_${data.record_id}/${data.componenteId}`
        }
        return historyObject;
      default: return historyObject;
    }

  }

  return { checkTypeNotification }

}